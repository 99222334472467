.App {
  text-align: center;
}

.grecaptcha-badge {
  display: none !important;
}

.App-header {
  background-color: #26282d;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-content {
  height: 100%; 
  width: 100%;
  background-color: #26282d;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; */
  
  max-width: 100vw;
  color: rgb(228, 228, 228);
  /* overflow: auto; */
}

.info-main-container {
  height: calc(100vh - 4rem); /* Subtract the top and bottom padding */
  margin-bottom: -2rem; /* Negative margin equal to the bottom padding */
  overflow-y: auto;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .info-main-container {
    height: calc(100vh); 
    margin-bottom: 0;
  }
}

.info-page {
  box-sizing: border-box;
  font-size: medium;
  text-align: left;
  max-width: 100vw;
}

.text-page-container {
  line-height: 1.6;
  overflow-y: auto;
  padding: 2rem 1rem;
  
}

.text-page-container p {
  font-size: 16px; /* Adjust as needed */
  margin-bottom: 1.2rem; /* Adjust as needed */
}

.text-page-container ul {
  list-style-type: disc; /* You can change this to none, circle, square, etc. */
  padding-left: 20px; /* Adjust as needed */
}

.text-page-container li {
  margin-bottom: 10px; /* Adjust as needed */
  line-height: 1.6; /* Adjust as needed */
}

.faq-main-container {
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; 
}
.faq-page{
  align-items: center;
  
}


.info-page a{
  color: #61dafb;
}

.info-page strong{
  font-size:x-large;
}